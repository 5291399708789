var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-subheader',[_vm._v(" Выберите год ")])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-select',{staticClass:"mb-5",attrs:{"items":_vm.years,"item-text":"text","item-value":"value","label":"Год","persistent-hint":"","return-object":"","single-line":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mr-5 mt-3",on:{"click":_vm.fetchPlan}},[_vm._v(" Показать план "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("mdi-file-table-outline")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn-toggle',{model:{value:(_vm.buttonToggle),callback:function ($$v) {_vm.buttonToggle=$$v},expression:"buttonToggle"}},[_c('v-btn',{on:{"click":_vm.exportTableToXLSX}},[_vm._v(" Выгрузить план в файл "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("mdi-microsoft-excel")])],1)],1)],1)],1),_c('KPIChart',{attrs:{"title":_vm.plan.name,"type":"line","direction":"shipment","rows":_vm.plan.indicators}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.options.headers,"items":_vm.plan.indicators,"loading":_vm.loading,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"footer-props":{
    itemsPerPageAllText: 'Все',
    itemsPerPageText: 'Строк на страницу'
  }},scopedSlots:_vm._u([{key:"item.deviation",fn:function(ref){
  var item = ref.item;
return [(item.deviation > 0)?_c('td',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.deviation)+" "),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"green"}},[_vm._v(" mdi-arrow-up-thin ")])],1):_c('td',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.deviation)+" "),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"red"}},[_vm._v(" mdi-arrow-down-thin ")])],1)]}},{key:"item.weightDeviation",fn:function(ref){
  var item = ref.item;
return [(item.weightDeviation > 0)?_c('td',{staticStyle:{"color":"green"}},[_vm._v(" "+_vm._s(item.weightDeviation)+" "),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"green"}},[_vm._v(" mdi-arrow-up-thin ")])],1):_c('td',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.weightDeviation)+" "),_c('v-icon',{staticClass:"ml-1",staticStyle:{"color":"red"}},[_vm._v(" mdi-arrow-down-thin ")])],1)]}}])}),_c('v-snackbar',{attrs:{"top":true,"timeout":-1},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.enrich.status))])]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.enrich.errorMessage)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" "+_vm._s(_vm.enrich.buttonClose)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }